export function loading(targetElement: HTMLElement, caption: string = "Loading") {
    const originalInnerHTML = targetElement.innerHTML;
    targetElement.innerHTML = `
    <span class="spinner-border spinner-border-sm" role="status"
          aria-hidden="true"></span>${caption}
    `;

    return function done() {
        targetElement.innerHTML = originalInnerHTML;
    };
}
