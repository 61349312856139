/// <reference types="webpack/module" />

import "@/styles/application.scss";
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";
import language from "@core/i18n/dataTables-es";

interface ApplicationWindow extends Window {
    Stimulus: Application;
}
declare const window: ApplicationWindow;

// Stimulus
const stimulus = Application.start();
if (process.env.NODE_ENV === "development") {
    stimulus.debug = true;
}

const stimulusContext = require.context("./stimulus/lib/controllers", true, /\.js$/);
stimulus.load(definitionsFromContext(stimulusContext));

window.Stimulus = stimulus;

// DataTables defaults
if ($.fn.dataTable) {
    $.extend($.fn.dataTable.defaults, {
        language: language,
    });
}
